import { createGlobalStyle } from "styled-components";
import GlobalFontStyle from "./fonts/GlobalFontStyle";
import { BrowserRouter as Router, Routes as Switch, Route } from "react-router-dom";
import Main from "./components/Main";
import Policy from "./components/Policy";

function App() {
  return (
    <>
      <GlobalFontStyle />
      <GlobalStyle />

      <Router>
        <Switch>
          <Route path={`${process.env.PUBLIC_URL}/`} element={<Main />} />
          <Route path={`${process.env.PUBLIC_URL}/policy`} element={<Policy />} />
        </Switch>
      </Router>
    </>
  );
}

const GlobalStyle = createGlobalStyle`
  html, body {
    padding: 0;
    margin: 0;
  }
`;

export default App;
